import { FC, useState } from 'react'
import RecallModal from '../common/RecallModal'
import { ChatTypeEnum } from '../../enums/ChatTypeEnum'
import { IErrorModel } from '../../validators/commonValidator'
import { FullScreen } from '../../stores/AppStore'
import { useRecoilValue } from 'recoil'
import { INomicModel } from '../../models/INomicModel'

interface ISharePointModalProps {
  open: boolean
  nomicPropertyModel: INomicModel
  onClose: () => void
  onNext: () => void
  loading?: boolean
}

const SharePointModal: FC<ISharePointModalProps> = (props) => {
  const { open, onClose, loading, onNext } = props

  const [errors, setErrors] = useState<IErrorModel>({})
  const fullScreen = useRecoilValue(FullScreen)

  const handleModalClose = () => {
    setErrors({})
    onClose()
  }

  const handleSaveProperty = async () => {
    handleModalClose()
    onNext()
  }

  return (
    <RecallModal
      chatType={ChatTypeEnum.CUSTOM_RECALL_APP}
      isShowing={open}
      onSave={handleSaveProperty}
      onClose={handleModalClose}
      disabled={!!Object.keys(errors).length}
      size={fullScreen ? 'medium' : 'small'}
      labelYes='Next'
      loadingYes={loading}
    >
      <p>Sharepoint coming very soon. Click 'Next' to select an organisation.</p>
    </RecallModal>
  )
}

export default SharePointModal
