import { getAsync, postAsync } from '../helpers/apiRequest'
import { ILangJson } from '../hooks/useLanguages'
import { IBodyRequestModel } from '../models/api/IBodyRequestModel'
import { IResponse } from '../models/api/IResponse'
import { ISystemBannerPostRequestModel } from '../models/api/ISystemBannerModel'
import { TokenExpiryWrapper } from './TokenManager'

export interface IAppVersion {
  version: string
}

export interface IFlagFilters {
  name: string
  parameters: {
    [y: string]: string
  }
}

export interface IFlagDetails {
  enabled: boolean
  client_filters?: IFlagFilters[]
}

export interface IAppFlags {
  [x: string]: IFlagDetails
}

export interface IAppConfig {
  NOMIC_AUTH0_DOMAIN: string
  NOMIC_AUTH0_CLIENT_ID: string
  NOMIC_AUTH0_AUDIENCE: string
  NOMIC_AUTH0_ROLES: string
  NOMIC_AUTH0_ISSUER: string
}

export interface ISystemBannerInfo {
  language: string
  message: string
  enabled: boolean
  lastModified: string
}

export const getAppVersion = TokenExpiryWrapper(
  (request: IBodyRequestModel): Promise<IResponse<IAppVersion>> => getAsync('/v1/app/version', request),
  [],
  null,
)

export const getAppFlags = TokenExpiryWrapper(
  (request: IBodyRequestModel): Promise<IResponse<IAppFlags>> => getAsync('/v1/app/flags', request),
  [],
  null,
)

export const getAppLanguages = TokenExpiryWrapper(
  (request: IBodyRequestModel): Promise<IResponse<ILangJson[]>> => getAsync('/v1/app/language', request),
  [],
  null,
)

export const getNomicAuthConfig = TokenExpiryWrapper(
  (request: IBodyRequestModel): Promise<IResponse<IAppConfig>> => getAsync('/v1/app/config', request),
  [],
  null,
)

export const getSystemBannersInfo = TokenExpiryWrapper(
  (request: IBodyRequestModel): Promise<IResponse<ISystemBannerInfo[]>> =>
    getAsync(`/v1/app/system-banner-message`, request),
  [],
  null,
)

export const addSystemBannerInfo = TokenExpiryWrapper(
  (request: ISystemBannerPostRequestModel): Promise<IResponse<boolean>> =>
    postAsync(`/v1/app/system-banner-message`, request),
  [],
  null,
)

// get the language subset reqiured for the logon screen
const FAIL_LANGS_RESPONSE = { success: false, data: null }
export const getLoginLanguages = async (): Promise<IResponse<ILangJson[]>> => {
  try {
    const response = await fetch('/v1/app/login')
    if (response.ok) return await response.json()
    return FAIL_LANGS_RESPONSE
  } catch {
    return FAIL_LANGS_RESPONSE
  }
}
