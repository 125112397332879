import { FC, useRef, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { Loader } from '@aurecon-creative-technologies/styleguide'

import { appInsights } from '../api/AppInsights'
import { getChatQuestions } from '../api/QuestionService'
import { ChatSession, ChatType, NotFound, ScrollChat } from '../stores/AppStore'
import Page from '../components/Page'
import ChatQuestion from '../components/ChatQuestion'
import ChatAnswer from '../components/ChatAnswer'
import ChatNotFound from '../components/ChatNotFound'
import FileDownloadModal from '../components/modals/FileDownloadModal'
import { useShowFeature } from '../hooks/useShowFeature'
import ChatSwitcher from '../components/ChatSwitcher'

import Style from '../styles/Home.module.sass'
import AnswerEditModal from '../components/modals/AnswerEditModal'
import { chatStandardConfig } from '../config/chatStandardsConfig'

interface ChatStandardsProps {
  appRoute: string
}

const ChatStandards: FC<ChatStandardsProps> = ({ appRoute }) => {
  const { chatId } = useParams<{ chatId: string }>()
  const config = chatStandardConfig[appRoute]

  const [chatSession, setChatSession] = useRecoilState(ChatSession)
  const [notFound, setNotFound] = useRecoilState(NotFound)
  const [scrollChat, setScrollChat] = useRecoilState(ScrollChat)
  const setChatType = useSetRecoilState(ChatType)
  const showFeature = useShowFeature(config?.featureFlag)
  const contentsRef = useRef<HTMLDivElement>(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!config) return
    setChatType(config.chatTypeEnum)

    return () => setChatType(null)
  }, [setChatType, config])

  useEffect(() => {
    if (showFeature?.enabled) return

    setChatType(null)
    location.hash = '#/'
  }, [showFeature, setChatType])

  useEffect(() => {
    const getSessionAsync = async () => {
      const id = chatId || ''
      const questions = await getChatQuestions({ chatId: id })

      if (!questions?.data) {
        setLoading(false)
        setNotFound(true)
        setChatSession(null)
        return
      }

      setChatSession({
        chatId: id,
        questions: questions.data,
        type: config.chatTypeEnum,
      })

      setLoading(false)
      setNotFound(false)

      setTimeout(() => {
        setScrollChat((s) => s + 1)
      }, 1000)
    }

    if (chatSession?.chatId === chatId) return

    setLoading(true)
    getSessionAsync()
  }, [chatSession, chatId, setChatSession, setNotFound, setScrollChat, config])

  useEffect(() => {
    if (!contentsRef.current) return

    contentsRef.current.scrollTo({ top: contentsRef.current.scrollHeight, behavior: 'smooth' })
  }, [scrollChat])

  if (appInsights) appInsights.trackPageView({ name: config.appInsightsName })

  return (
    <Page menu contentWrapper contentsRef={contentsRef}>
      <h1 className={Style.chatTitle}>{config.title}</h1>
      <ChatSwitcher activeChat={config.chatTypeEnum} />
      {loading && <Loader label='Loading chat...' />}
      {!loading && notFound && <ChatNotFound />}
      {!loading &&
        chatSession?.questions.map((question) => (
          <div key={question.rowKey} id={`qa-holder-${question.rowKey}`}>
            <ChatQuestion question={question} />
            <ChatAnswer question={question} />
          </div>
        ))}
      <FileDownloadModal />
      <AnswerEditModal />
    </Page>
  )
}

export default ChatStandards
