export const FeatureFlagEnum = {
  ShowCodeAssistantChat: 'WEBAPP__APP__ShowCodeAssistantChat',
  ShowBambooChat: 'WEBAPP__APP__ShowBambooChat',
  ShowInfoMessage: 'WEBAPP__Frontend__ShowInfoMessage',
  ShowCustomRecallApp: 'WEBAPP__APP__ShowCustomRecallApp',
  ShowBHPStandardsApp: 'WEBAPP__APP__ShowBHPStandardsApp',
  ShowRioTintoStandardsApp: 'WEBAPP__APP__ShowRioTintoStandardsApp',
  ShowAusNetStandardsApp: 'WEBAPP__APP__ShowAusNetStandardsApp',
  ShowHomepageSlides: 'WEBAPP__Frontend__ShowHomepageSlides',
}
