import { FC, useEffect, useState } from 'react'
import { Button, Icon, Loader, Pill, Tooltip, useToast } from '@aurecon-creative-technologies/styleguide'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism'

import { IQuestion } from '../models/IQuestionModels'
import AnswerActions from './AnswerActions'

import { appInsights } from '../api/AppInsights'
import { useLanguages } from '../hooks/useLanguages'

import Style from '../styles/ChatAnswer.module.sass'
import nextId from 'react-id-generator'
import { openLink } from '../helpers/utils'

const FIRST_ANSWER = 0
const CAPABILITY_TEXT_MAX_RANGE = 100

interface IChatAnswerProps {
  question: IQuestion
}

const ChatAnswer: FC<IChatAnswerProps> = (props) => {
  const [selectedAnswer, setSelectedAnswer] = useState(FIRST_ANSWER)
  const { addToast } = useToast()
  const { question } = props
  const { t } = useLanguages()

  useEffect(() => {
    setSelectedAnswer(question.answers.length - 1)
  }, [question, setSelectedAnswer])

  const answerRaw = question.answers[selectedAnswer]?.answer
  const answer = question.loading ? answerRaw || '*Thinking...*' : answerRaw || '*No answer found*'

  const handleSource = (link: string) => {
    if (!link) return
    if (appInsights) appInsights.trackEvent({ name: 'Recall Response QuickLink', properties: { link } })

    openLink(link, true, true)
  }

  const copyCode = (code: string) => {
    navigator.clipboard.writeText(code)

    addToast({
      type: 'success',
      title: t('popup_toast'),
      message: t('popup_mess14'),
      timeout: 5000,
      timeLabel: t('popup_toast_timelabel'),
    })
  }

  const renderCapabilities = () => {
    return question.capabilities.map((capability) => (
      <Pill colour={1} key={capability} size='large' cssClass={Style.capabilityPill}>
        <span className={Style.capPillContent}>
          <Icon type='business_center' outlined={true} size='18px' className={Style.capIcon} />
          {capability.length > CAPABILITY_TEXT_MAX_RANGE ? (
            <Tooltip show={capability}>
              <span className={Style.longText}>{capability}</span>
            </Tooltip>
          ) : (
            <span className={Style.longText}>{capability}</span>
          )}
        </span>
      </Pill>
    ))
  }

  return (
    <div className={Style.answer}>
      <div className={Style.avatar}>
        {question.loading ? <Loader size='extra small' /> : <Icon type='smart_toy' outlined />}
      </div>
      <div className={Style.content}>
        {!!question.capabilities.length && (
          <div className={Style.quickLinks}>
            <div className={Style.label}>Capabilities</div>
            <div className={Style.links}>{renderCapabilities()}</div>
          </div>
        )}
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          components={{
            code(props) {
              const { children, className, ...rest } = props
              const match = /language-(\w+)/.exec(className || '')

              if (!match)
                return (
                  <code {...rest} className={className}>
                    {children}
                  </code>
                )

              const code = String(children)

              return (
                <div className={Style.codeBlock}>
                  <SyntaxHighlighter {...rest} style={oneDark} language={match[1]} PreTag='div'>
                    {code.replace(/\n$/, '')}
                  </SyntaxHighlighter>
                  <Button
                    type='icon-square'
                    icon='content_copy'
                    size='small'
                    onClick={() => copyCode(code)}
                    cssClass={Style.copyCodeBtn}
                  />
                </div>
              )
            },
          }}
        >
          {answer}
        </ReactMarkdown>
        {!!question.sources.length && (
          <div className={Style.quickLinks}>
            <div className={Style.label}>Quick links</div>
            <div className={Style.links}>
              {question.sources.map((source) => {
                return (
                  <Button
                    key={nextId()}
                    icon='link'
                    type='secondary'
                    default
                    label={source.label}
                    cssClass={Style.linkButton}
                    onClick={() => handleSource(source.link)}
                    size='small'
                  />
                )
              })}
            </div>
          </div>
        )}
        <AnswerActions question={question} selectedAnswer={selectedAnswer} />
      </div>
    </div>
  )
}

export default ChatAnswer
