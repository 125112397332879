import Joi from 'joi'
import { validateSpecialCharacters } from '../helpers/utils'

export const NomicOrgModalFields = {
  newOrgName: 'newOrgName',
}

export const nomicOrgModalSchema = () => {
  return Joi.object({
    [NomicOrgModalFields.newOrgName]: Joi.string()
      .trim()
      .min(1)
      .max(200)
      .required()
      .custom((text, helper) => {
        const validForSpecialChars = validateSpecialCharacters(text)
        if (!validForSpecialChars) {
          return helper.message({ custom: 'Cannot contain special character in Organisation name' })
        }
        return true
      })
      .messages({ 'string.empty': 'Organisation Name is required' }),
  })
}
