import { getAsync } from '../helpers/apiRequest'
import { IBodyRequestModel } from '../models/api/IBodyRequestModel'
import { IResponse } from '../models/api/IResponse'
import { IUserRequestModel } from '../models/api/IUserRequestModel'
import { IUserModel } from '../models/IUserModel'
import { TokenExpiryWrapper } from './TokenManager'

export interface ILoggedUser {
  department: string
}

export const searchUsers = TokenExpiryWrapper(
  (request: IUserRequestModel): Promise<IResponse<IUserModel[]>> => {
    const query = request.external ? '?external=true' : ''
    return getAsync(`/v1/users/search/${request.email}${query}`, request)
  },
  [],
  null,
)

export const getLoggedUser = TokenExpiryWrapper(
  (request: IBodyRequestModel): Promise<IResponse<ILoggedUser>> => getAsync('/v1/users/user', request),
  [],
  null,
)
